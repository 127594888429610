import { Switch, Match, createSignal, onMount, type Ref } from "solid-js";
import { debounce } from "@solid-primitives/scheduled";

import type { SubmissionValues } from "~/types/common";
import type {
  InputOption,
  InputOptionWithRealEstateContext,
} from "~/components/Forms/Fields/Base/InputTypesProps";
import { InputTypeLocationAutoComplete } from "~/components/Forms/Fields/Base/InputTypeLocationAutoComplete";
import { Select } from "~/components/Forms/Fields/Base/Select";

import { createMemo, Show } from "solid-js";
import { useSearchContext } from "~/contexts/SearchContext";
import { createStore } from "solid-js/store";
import { DebugDumper } from "~/components/Forms/Utils/DebugDumper";

import {
  type EventZone,
  type EventClickName,
  useEventsContext,
} from "~/contexts/EventsContext";

import IconClose from "~/img/icons/close.svg";
import IconSearch from "~/img/icons/search.svg";
import type { SearchParams } from "~/types/drupal_jsonapi";
import { submitSearchCountForm } from "./server";

type SearchFormProps = {
  // Fields configuration
  enableLocation?: boolean;
  enableRooms?: boolean;
  enableBudget?: boolean;
  enableMarketingSegments?: boolean;
  enableRegulations?: boolean;
  enableLotTypes?: boolean;
  enableSaleStates?: boolean;
  // UI
  enableSearchButton?: boolean;
  enableCloseButton?: boolean;
  enableAdvancedSearch?: boolean;
  // Default state of the advanced search options, displayed or not.
  isAdvancedSearchOpen?: boolean;
  // Events related options
  zone?: EventZone;
  shouldLookupZoneInDom?: boolean;
  eventClickName?: EventClickName;
  // When closing, we might want to do something.
  callbackWhenCloseButtonIsClicked?: () => void;
};

let formRef: Ref<HTMLFormElement>;

export type CountsState = {
  pending: boolean;
  result: {
    count: number;
    count_preview: number;
    count_proximity: number;
  };
  error?: Error;
};

export default function SearchForm(props: SearchFormProps) {
  const [, { sendClick }] = useEventsContext();
  const [searchState, searchActions] = useSearchContext();
  const [store, setStore] = createStore<SubmissionValues>({});
  const [counts, setCounts] = createStore<Counts>({
    result: {
      count: 0,
      count_preview: 0,
      count_proximity: 0,
    },
    pending: false,
  });

  onMount(() => {
    // Open advanced search params if applicable
    props.isAdvancedSearchOpen ||
      searchState.searchResults?.search_params.lot_types ||
      (searchState.searchResults?.search_params.sale_states &&
        searchActions.setAdvancedSearchOpen!());

    // Set the search store from search params
    // we need to cast arrays to strings separated by commas
    if (searchState.searchResults?.search_params) {
      setStore(
        castSearchParamsToString(searchState.searchResults?.search_params),
      );
    }
  });

  const [userHasInteractedWithSearch, setUserHasInteractedWithSearch] =
    createSignal(false);

  const searchButtonDisabled = createMemo(() => {
    return (
      counts.result?.count === 0 &&
      counts.result?.count_preview === 0 &&
      counts.result?.count_proximity === 0 &&
      userHasInteractedWithSearch()
    );
  });

  const searchButtonLabel = createMemo(() => {
    if (counts.pending) {
      return "Searching...";
    } else {
      if (
        !userHasInteractedWithSearch() ||
        (!userHasInteractedWithSearch() && searchButtonDisabled())
      ) {
        return "Rechercher";
      }
      if (
        counts.result?.count === 0 &&
        counts.result?.count_proximity === 0 &&
        counts.result?.count_preview === 0
      ) {
        return "0 offre";
      }

      if (counts.result?.count === 1) {
        return "1 offre";
      }

      if (counts.result?.count > 1) {
        const count = counts.result?.count.toLocaleString("fr-FR");
        return `${count} offres`;
      }

      if (counts.result?.count_proximity > 1) {
        const count = counts.result?.count_proximity.toLocaleString("fr-FR");
        return `${count} offres à prox.`;
      }

      if (counts.result?.count_proximity === 1) {
        return "1 offre";
      }

      if (counts.result?.count_preview === 1) {
        return "1 avant-première";
      }

      if (counts.result?.count_preview > 1) {
        const count = counts.result?.count_preview.toLocaleString("fr-FR");
        return `${count} avant-premières`;
      }
    }
  });

  const debouncedCount = debounce(async (store: SubmissionValues) => {
    setCounts("pending", true);
    const c = await submitSearchCountForm<Counts["result"]>(store);

    if (isSearchCountOk(c)) {
      setCounts({
        pending: false,
        result: c,
        error: undefined,
      });
    }
    setCounts("pending", false);
  }, 500);

  return (
    <>
      <form
        ref={formRef}
        action="/programme-immobilier-neuf/"
        class="search-form"
        classList={{ "with-criterion": searchState.advancedSearchIsOpen }}
        method="post"
        onInput={() => {
          setUserHasInteractedWithSearch(true);
          debouncedCount(store);
        }}
        onChange={() => {
          setUserHasInteractedWithSearch(true);
          debouncedCount(store);
        }}
      >
        <div class="inner">
          {/* Disables submit return key press: none JavaScript solution. */}
          <button
            type="submit"
            disabled
            style="display: none"
            aria-hidden="true"
          />
          <i aria-hidden="true" class="cog-icon icon-search">
            <IconSearch />
          </i>
          <p class="title">Rechercher</p>
          <Show when={props.enableLocation}>
            <InputTypeLocationAutoComplete
              name="location"
              label="Ville, dpt., programme"
              defaultFormStorage={setStore}
              value={store.location}
              valueCity={store.city}
              valueDepartement={store.department}
              valueRegion={store.region}
              valueCountry={store.country}
              valuePostalCode={store.postal_code}
              valueLat={store.lat ? parseFloat(store.lat) : undefined}
              valueLng={store.lng ? parseFloat(store.lng) : undefined}
            />
          </Show>
          <Show when={props.enableRooms}>
            <Select
              label="Nb de pièces"
              name="rooms"
              options={SearchOptions().optionsRooms}
              multiple
              defaultFormStorage={setStore}
              value={store.rooms}
            />
          </Show>
          <Show when={props.enableBudget}>
            <Select
              name="budget"
              label="Budget max."
              options={SearchOptions().optionsBudget}
              defaultFormStorage={setStore}
              value={store.budget}
            />
          </Show>
          <Show when={props.enableMarketingSegments}>
            <Select
              label="Solution Cogedim"
              name="segments_mkg"
              options={SearchOptions().optionsMarketingSegments}
              multiple
              defaultFormStorage={setStore}
              value={store.segments_mkg}
            />
          </Show>
          <Show when={props.enableRegulations}>
            <Select
              label="Dispositif fiscal"
              name="regulations"
              options={SearchOptions().optionsRegulations}
              multiple
              hidden={!searchState.advancedSearchIsOpen}
              defaultFormStorage={setStore}
              value={store.regulations}
            />
          </Show>
          <Show when={searchState.advancedSearchIsOpen}>
            <Show when={props.enableLotTypes}>
              <Select
                label="Type de bien"
                name="lot_types"
                options={SearchOptions().optionsLotTypes}
                multiple
                defaultFormStorage={setStore}
                value={store.lot_types}
              />
            </Show>

            <Show when={props.enableSaleStates}>
              <Select
                label="Avancement"
                name="sale_states"
                options={SearchOptions().optionsSaleStates}
                clearable={true}
                multiple
                defaultFormStorage={setStore}
                value={store.sale_states}
              />
            </Show>
          </Show>
          <Show
            when={
              props.enableAdvancedSearch && !searchState.advancedSearchIsOpen
            }
          >
            <div class="more-criterion">
              <button
                type="button"
                onClick={() => searchActions.setAdvancedSearchOpen!()}
                data-test="btn-more-criterion"
              >
                + de critères
              </button>
            </div>
          </Show>
          <Show
            when={
              props.enableAdvancedSearch && searchState.advancedSearchIsOpen
            }
          >
            <div class="more-criterion">
              <button
                type="button"
                onClick={() => searchActions.setAdvancedSearchClose!()}
                data-test="btn-less-criterion"
              >
                - de critères
              </button>
            </div>
          </Show>
          <Show when={props.enableSearchButton}>
            <div class="button-search">
              <Switch
                fallback={
                  <button
                    class="btn"
                    type="submit"
                    disabled={searchButtonDisabled()}
                    onClick={() => {
                      sendClick(
                        props.eventClickName || "btn-search",
                        props.zone
                          ? props.zone
                          : props.enableAdvancedSearch
                            ? "topbar"
                            : "first-screen",
                      );
                    }}
                    data-test="btn-search"
                  >
                    {searchButtonLabel()}
                  </button>
                }
              >
                <Match when={counts.pending} keyed>
                  <button
                    class="btn"
                    type="submit"
                    disabled
                    data-test="btn-search"
                  >
                    <i aria-hidden="true" class="loading-spinner" />
                  </button>
                </Match>
                <Match when={"error" in counts} keyed>
                  <button
                    class="btn"
                    type="submit"
                    disabled
                    data-test="btn-search"
                  >
                    🥲
                  </button>
                </Match>
              </Switch>
              <Show when={import.meta.env.VITE_COG_DEBUG_FORMS === "1"} keyed>
                <DebugDumper
                  name="SearchForm"
                  data={{
                    store,
                    results: counts.result,
                    params: searchState.searchResults?.search_params,
                  }}
                />
              </Show>
            </div>
          </Show>
          <Show when={props.enableCloseButton}>
            <button
              type="button"
              class="btn btn-icon button-close"
              aria-label="Fermer"
              onClick={() => searchActions.setSearchBarClose!()}
              data-test="search-close"
            >
              <i aria-hidden="true" class="cog-icon">
                <IconClose />
              </i>
            </button>
          </Show>
        </div>
      </form>
    </>
  );
}

export function SearchOptions(): Record<
  string,
  InputOption[] | InputOptionWithRealEstateContext[]
> {
  return {
    optionsBudget: [
      {
        label: "Non précisé",
        value: "-1",
        name: "budget-not-specified",
      },
      {
        label: "Moins de 100 000 €",
        value: "100000",
        name: "budget-100000",
      },
      {
        label: "200 000 €",
        value: "200000",
        name: "budget-200000",
      },
      {
        label: "300 000 €",
        value: "300000",
        name: "budget-300000",
      },
      {
        label: "400 000 €",
        value: "400000",
        name: "budget-400000",
      },
      {
        label: "500 000 €",
        value: "500000",
        name: "budget-500000",
      },
      {
        label: "600 000 €",
        value: "600000",
        name: "budget-600000",
      },
      {
        label: "700 000 €",
        value: "700000",
        name: "budget-700000",
      },
      {
        label: "800 000 €",
        value: "800000",
        name: "budget-800000",
      },
      {
        label: "900 000 € et +",
        value: "900000",
        name: "budget-900000",
      },
    ],
    optionsRooms: [
      {
        label: "1 pièce",
        value: "1",
        name: "rooms-1",
      },
      {
        label: "2 pièces",
        value: "2",
        name: "rooms-2",
      },
      {
        label: "3 pièces",
        value: "3",
        name: "rooms-3",
      },
      {
        label: "4 pièces",
        value: "4",
        name: "rooms-4",
      },
      {
        label: "5 pièces et +",
        value: "5",
        name: "rooms-5",
      },
    ],
    optionsLotTypes: [
      {
        label: "Appartement",
        value: "Appartement",
        name: "apartment",
      },
      {
        label: "Maison",
        value: "Maison",
        name: "house",
      },
      {
        label: "Terrain",
        value: "Terrain",
        name: "plot",
      },
    ],
    optionsSaleStates: [
      {
        label: "Avant-première",
        value: "Avant-première",
        name: "preview",
      },
      {
        label: "En lancement",
        value: "En lancement",
        name: "launching",
      },
      {
        label: "En commercialisation",
        value: "En commercialisation",
        name: "commercialization",
      },
      {
        label: "En travaux",
        value: "En travaux",
        name: "build",
      },
      {
        label: "Livraison imminente",
        value: "Prêt à emménager",
        name: "delivery",
      },
      /* TODO: faire le point sur cet état*/
      /*{
        label: "Prêt à emménager",
        value: "Prêt à emménager",
        name: "ready",
      },*/
    ],
    optionsRegulations: [
      {
        label: "Pinel",
        value: "Pinel",
        name: "pinel",
        disabled: false,
        isDwell: false,
        isInvest: true,
      },
      {
        label: "Pinel +",
        value: "Pinel +",
        name: "pinel-plus",
        disabled: false,
        isDwell: false,
        isInvest: true,
      },
      {
        label: "LMNP",
        value: "LMNP",
        name: "lmnp-vat-inc",
        disabled: false,
        isDwell: false,
        isInvest: true,
      },
      {
        label: "LMNP géré",
        value: "LMNP géré",
        name: "lmnp-vat-ex",
        disabled: false,
        isDwell: false,
        isInvest: true,
      },
      {
        label: "Nue-propriété",
        value: "Nue-propriété",
        name: "bare-ownership",
        disabled: false,
        isDwell: false,
        isInvest: true,
      },
      {
        label: "Patrimonial",
        value: "Patrimonial",
        name: "patrimonial",
        disabled: false,
        isDwell: false,
        isInvest: true,
      },
    ],
    optionsMarketingSegments: [
      {
        label: "Cogedim Access",
        value: "Cogedim Access",
        name: "cogedim-access",
      },
      /*{
        label: "Cogedim Invest",
        value: "Cogedim Invest",
        name: "cogedim-invest",
      },
      {
        label: "Cogedim Signature",
        value: "Cogedim Signature",
        name: "cogedim-signature",
      },*/
    ],
  };
}

function castSearchParamsToString(params: SearchParams): SubmissionValues {
  return {
    ...params,
    lot_types: params.lot_types?.join(","),
    rooms: params.rooms?.join(","),
    sale_states: params.sale_states?.join(","),
    segments_mkg: params.segments_mkg?.join(","),
    regulations: params.regulations?.join(","),
    budget: String(params.budget),
    lat: String(params.lat),
    lng: String(params.lng),
  };
}

function isSearchCountOk(
  response: Counts["result"] | Error,
): response is Counts["result"] {
  return "count" in response! && "count_preview" in response;
}
